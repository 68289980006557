import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PaymentsPage = () => (
  <Layout>
    <SEO title="お支払い方法" />
      <p className="text-textblack text-center text-3xl lg:text-4xl self-center pt-5">お支払い方法</p>
    <div className="container space-y-5 px-5 lg:px-0 my-8 justify-center content-center">
      <p className="text-textblack text-xl text-center">当店では、以下の支払い方法がご利用いただけます。</p>
      <ul class="list-disc ml-10">
          <li className="text-textblack">現金</li>
          <li className="text-textblack">各種クレジットカード(Visa, MasterCard, JCB, Diners, Amex, Discover)一括のみ対応。タッチ対応。</li>
          <li className="text-textblack">電子マネー(交通系, ApplePay, iD, QUICPay)</li>
          <li className="text-textblack">QR決済(PayPay, LINEPay, d払い, auPay)</li>
          <li className="text-textblack">行田市商品券(実施期間中のみ)</li>
        </ul>
      <p className="text-textblack">なお、決済端末の故障や、決済事業者のメンテナンス等によりご利用いただけない場合がございます。</p>
      
    </div>
  </Layout>
)

export default PaymentsPage